import React from 'react';

import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { getLayout } from 'src/layouts/SiteLayout/EmptyPageLayout';
import Landing from 'src/components/landing';

import { noIndexEnabled, propellerAdsTrackingId } from 'src/config.client';

const Home = () => {
  const { t } = useTranslation();
  const random = Math.floor(Math.random() * (3008 - 2502)) + 2502;

  return (
    <div className="animate__animated animate__fadeIn">
      <Head>
        <title>{t('pages.landing.title')}</title>
        <meta name="keywords" content={t('pages.landing.metaKeywords')} />
        <meta name="description" content={t('pages.landing.metaDescription')} />
        {noIndexEnabled && <meta name="robots" content="noindex, nofollow" />}
        <script type="application/javascript" src="js/bootstrap.bundle.min.js" />
        {propellerAdsTrackingId && (
          <script type="text/javascript" src={`https://propeller-tracking.com/fv.js?t=${propellerAdsTrackingId}`} />
        )}
      </Head>
      <Landing random={random} />
    </div>
  );
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

Home.getLayout = (page) => getLayout(page, 'illustration-bg');

export default Home;
