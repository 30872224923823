import styled from 'styled-components';
import Media from 'src/utils/media';
import { Input } from 'src/components/common';

export const HomeSection = styled.section`
  min-height: 60vh;
`;

export const Title = styled.h1`
  font-size: 46px;
  font-weight: 700;

  ${Media.md`
      font-size: 36px;
  `}

  > label {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    vertical-align: middle;
    text-align: center;
    color: #16171b;
    padding: 6px 12px;
    background: linear-gradient(30deg, #5db8fb, #35ea94);

    ${Media.md`
      font-size: 18px;
      padding: 4px 8px;
    `}
  }
`;

export const Subtitle = styled.span`
  color: #e6e6e7;
  font-size: 20px;
  font-weight: 300;

  ${Media.md`
    font-size: 18px;
  `}
`;

export const FormInput = styled(Input)`
  height: 72px !important;
  width: 86%;
  display: inline-block;
  font-size: 20px;
  font-weight: 300;
  color: #16171b;
  border: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  &:focus {
    border: 1px solid #35ea94;
    box-shadow: 0 0 3px #35ea94;
    -moz-box-shadow: 0 0 3px #35ea94;
    -webkit-box-shadow: 0 0 3px #35ea94;
  }

  ${Media.md`
    height: 48px!important;
    font-size: 16px;
    width: 80%;
  `}
`;

export const FormInputFull = styled(Input)`
  width: 100%;
  display: block;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
`;

export const FormButton = styled.button`
  height: 72px !important;
  font-size: 24px;

  &.btn-primary {
    background-color: #ef68a3;
    border: none;
  }

  &.btn-primary:hover {
    background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
  }

  &.btn-primary:focus {
    box-shadow: none !important;
  }

  ${Media.md`
    height: 48px!important;
    font-size: 16px;
  `}
`;

export const SectionTitle = styled.h2`
  font-size: 46px;
  font-weight: 700;
  text-align: center;

  > b {
    color: #35ea94;
  }

  ${Media.md`
    font-size: 32px;
  `}
`;

export const SectionSubtitle = styled.p`
  color: #e6e6e7;
  font-size: 16px;
  font-weight: 300;
  text-align: center;

  ${Media.md`
    font-size: 14px;
  `}
`;

export const SectionContent = styled.div`
  border-bottom: 1px solid #434343;
`;

export const TryButton = styled.button`
  height: 72px;
  background: linear-gradient(64.25deg, #5db8fb 15.64%, #35ea94 94.68%);
  border-radius: 4px;
  color: #16171b;
  font-size: 24px;
  font-weight: 400;
  border: none;

  &:hover {
    background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
    color: #16171b;
  }
`;

export const ButtonWrapper = styled.div`
  background: #16171b;
  position: relative;
  background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
  border-radius: 4px;
  padding: 1px;
  display: flex;
`;

export const TryButtonEmpty = styled.button`
  width: 100%;
  height: 72px;
  font-size: 24px;
  border: none;
  background-color: #16171b;
  color: #f9faff;
  font-weight: 300;

  &:hover {
    background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
    color: #f9faff;
  }
`;

export const Feature = styled.div`
  > h4 {
    text-align: center;
    color: #f9faff;
    font-size: 18px;
    font-weight: 700;
  }

  > p {
    text-align: center;
    color: #e6e6e7;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const FeatureIcon = styled.i`
  margin: 0 auto;
  padding: 24px;
  text-align: center;
  vertical-align: middle;
  font-size: 32px;
  border-radius: 50%;
  background: radial-gradient(circle at bottom, #5db8fb 0%, #ef68a3 50%, #35ea94 100%);

  &::before {
    display: block;
    width: 32px;
  }
`;

export const MissionPoint = styled.div`
  > h3 {
    color: #f9faff;
    font-size: 28px;
    font-weight: 700;

    ${Media.md`
      font-size: 20px;
    `}
  }

  > p {
    color: #e6e6e7;
    font-size: 14px;
    font-weight: 300;

    ${Media.md`
      font-size: 12px;
    `}
  }
`;

export const AccordionItem = styled.div`
  background: none;
  border-top: 1px solid #434343;
  border-bottom: 1px solid #434343;
  border-left: none;
  border-right: none;
  border-radius: 0 !important;
`;

export const AccordionHeader = styled.h2`
  border: none !important;
`;

export const AccordionButton = styled.button`
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  color: #f9faff;
  font-size: 12px;

  &:not(.collapsed) {
    color: #f9faff;
  }

  &:focus {
    box-shadow: none;
    border: none;
  }

  &::after {
    content: '+';
    display: block;
    background: none;
    width: auto;
    height: auto;
    font-size: 28px;
    color: #35ea94;
    padding-left: 12px;
  }

  &:not(.collapsed)::after {
    content: '+';
    display: block;
    background: none;
    font-size: 28px;
    color: #ef68a3;
    transform: rotate(-45deg);
    transform-origin: center center;
  }
`;

export const AccordionBody = styled.div`
  font-size: 11px;
  font-weight: 300;
  color: #e6e6e7;
`;

export const Feedback = styled.div`
  border-bottom: 1px solid #434343;

  &::before {
    content: '';
    width: 24px;
    height: 18px;
    display: block;
    position: absolute;
    top: 10px;
    right: 5%;
    background-image: url('/img/quote.svg');
  }
`;

export const FeedbackHeader = styled.div`
  > span {
    text-align: right;
    color: #8a8a8a;

    ${Media.md`
      font-size: 14px;
      text-align: center;
    `}
  }

  > h5 {
    ${Media.md`
      font-size: 18px;
      text-align: center;
    `}
  }
`;

export const FeedbackText = styled.p`
  font-size: 12px;
  font-weight: 300;
`;

export const Thumbnail = styled.img`
  padding: 0;
  max-width: 100%;
  height: auto;
`;

export const FormSection = styled.section`
  height: 100vh;
  position: relative;
  background: linear-gradient(235.44deg, #35ea94 10.36%, #5db8fb 52.71%, #ef68a3 96.87%);

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 70vmin;
    height: 70vmin;
    top: 20vmin;
    left: 10vmin;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('/img/art.svg');
    z-index: 1;
    opacity: 0.6;
  }
`;

export const FormContent = styled.div`
  position: relative;
  z-index: 2;
`;

export const FormTitle = styled.h2`
  font-size: 64px;
  font-weight: 700;

  ${Media.md`
    font-size: 42px;
  `}
`;

export const FormSubtitle = styled.span`
  font-size: 26px;
  font-weight: 300;

  ${Media.md`
    font-size: 20px;
  `}
`;

export const FormNote = styled.span`
  font-size: 18px;
  font-weight: 300;

  ${Media.md`
    font-size: 14px;
  `}
`;
