import React, { useEffect, useState } from 'react';

import { Container, Form } from 'reactstrap';
import { Trans } from 'react-i18next';
import { EmojiIcon } from 'src/components/common';
import Modal from 'react-modal';
import Media from 'src/utils/media';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import {
  AccordionBody,
  AccordionButton,
  AccordionHeader,
  AccordionItem,
  ButtonWrapper,
  Feature,
  FeatureIcon,
  Feedback,
  FeedbackHeader,
  FeedbackText,
  FormButton,
  FormContent,
  FormInput,
  FormInputFull,
  FormNote,
  FormSection,
  FormSubtitle,
  FormTitle,
  HomeSection,
  MissionPoint,
  SectionContent,
  SectionSubtitle,
  SectionTitle,
  Subtitle,
  Thumbnail,
  Title,
  TryButton,
  TryButtonEmpty,
} from './basic';
import retrieveTracks from 'lib/retrieveTracks';
import axios from 'axios';
import { API_DEMO_CHECK_URL, API_DEMO_REGISTER, EULA, PRIVACY_POLICY } from 'src/const/routes';
import { useTranslation } from 'next-i18next';
import FacebookPixel from 'src/utils/facebookPixel';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import { Packages } from 'src/pages/panel/billing';
import { welcomeBonusPercents } from 'src/config.client';
import { useSelector } from 'react-redux';
import { CONSENT_COOKIE, LANDING_PROMO_MODAL_COOKIE } from '../../const/cookies';
import { trackLead, trackRegistration } from '../../api/metrics';

const ProtocolSelect = styled.span`
  display: inline-block;
  width: 14%;
  text-align: center;
  border-radius: 4px 0 0 4px !important;
  background-color: #e9e9e9;
  padding: 21px 0;
  font-size: 20px;
  font-weight: 300;
  color: #16171b;
  border: none;

  ${Media.md`
    width: 20%;
    padding: 12px 0;
    font-size: 16px;
  `}
`;

const Discount = styled.span`
  font-weight: 300;

  strong {
    font-weight: 700;
    color: #000;
    padding: 2px 4px;
    background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);

    &.contrast {
      color: #ffffff;
      background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);
    }
  }
`;
const BuyButton = styled.button`
  width: 100%;
  font-size: 18px;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(30deg, #5db8fb, #35ea94);
  color: #f9faff;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    background: linear-gradient(120deg, #5db8fb, #35ea94);
    color: #f9faff;
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const BackButton = styled.button`
  width: 100%;
  font-size: 18px;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  text-align: center;
  background-color: #27293d;
  color: #f9faff;
  font-weight: 700;

  i {
    color: #ffffff;
    font-size: 14px;
    margin-right: 10px;
  }
`;
Modal.setAppElement('body');
const Landing = ({ random }) => {
  const { t } = useTranslation();
  const [consentCookies, setConsentCookies] = useState(!!Cookies.get(CONSENT_COOKIE));
  const [step, setStep] = useState(0);
  const [secureProtocol, setSecureProtocol] = useState(true);
  const [url, setUrl] = useState('');
  const [feedback, setFeedback] = useState('');
  const [emailFeedback, setEmailFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [violations, setViolations] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isAllowModal, setIsAllowModal] = useState(!Cookies.get(LANDING_PROMO_MODAL_COOKIE) && consentCookies);
  const [isDisable, setIsDisable] = useState(false);

  const token = useSelector((state) => state.user.token);
  const utm = retrieveTracks();
  let violatedUrl = false;

  const getInViewElement = (element) => {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element.getBoundingClientRect();
    const pageTop = window.scrollY;
    const pageBottom = pageTop + window.innerHeight;
    const elementTop = elemRect.top - bodyRect.top;
    const elementBottom = elementTop + element.offsetHeight;

    if (elementTop <= pageBottom && elementBottom >= pageTop) {
      return element;
    }

    return null;
  };

  useEffect(() => {
    if (consentCookies) {
      setIsAllowModal(!Cookies.get(LANDING_PROMO_MODAL_COOKIE));
    }
  }, [consentCookies]);

  useEffect(() => {
    switch (step) {
      case 1:
        for (let i = 0; i < violations.length; i++) {
          const { propertyPath, message } = violations[i];

          if (propertyPath === 'url') {
            violatedUrl = true;
            setFeedback(message);
          }
        }

        if (!violatedUrl) {
          document.getElementById('form').scrollIntoView();
          setViolations([]);
          trackLead();
          setStep(step + 1);
        }
        break;
      case 2:
      case 3:
      case 4:
        setTimeout(() => setStep(step + 1), 1000);
        break;
      case 5:
        for (let i = 0; i < violations.length; i++) {
          const { propertyPath, message } = violations[i];

          if (propertyPath === 'email') {
            setEmailFeedback(message);
          }
        }

        if (emailCheck && !emailFeedback) {
          setViolations([]);
          trackRegistration();
          setStep(step + 1);
        }
        break;
      default:
        // skip
        break;
    }
  }, [step, violations, feedback, emailFeedback, emailCheck, email]);

  useEffect(() => {
    const animatedElements = document.querySelectorAll('[data-animate]');

    animatedElements.forEach((el) => el.classList.add('invisible'));

    window.addEventListener('scroll', () => {
      for (let el of animatedElements) {
        const viewedElement = getInViewElement(el);
        if (viewedElement) {
          viewedElement.classList.remove('invisible');
          viewedElement.classList.add('animate__animated', `animate__${viewedElement.dataset.animate}`);
        }
      }
    });
    const scrollElements = document.querySelectorAll('[data-scroll]');
    for (let scrollElement of scrollElements) {
      scrollElement.onclick = (e) => {
        e.preventDefault();
        const scrollToId = e.target.dataset.scroll;
        document.querySelector(scrollToId).scrollIntoView();
      };
    }
    const urlElements = document.querySelector('#url');
    urlElements.onclick = () => {
      const noteElements = document.querySelector('#note');
      noteElements.classList.remove('invisible');
      noteElements.classList.add('animate__animated', 'animate__fadeInUp');
    };

    const urlFormElements = document.querySelector('#url-form');
    urlFormElements.onclick = () => {
      const noteFormElements = document.querySelector('#note-form');
      noteFormElements.classList.remove('invisible');
      noteFormElements.classList.add('animate__animated', 'animate__fadeInUp');
    };
  }, []);

  // TODO refactor this hell, create custom step-wizard component
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // handle 1st step
      if (url && !email) {
        setIsDisable(true);
        const {
          data: { allowed, reason },
        } = await axios.post(API_DEMO_CHECK_URL, { url: secureProtocol ? `https://${url}` : `http://${url}` });

        // TODO for now we skip no_ga and allow it to reg
        if (!allowed && reason !== 'no_ga') {
          setFeedback(t('components.landing.index.reasons.' + reason));
        } else {
          FacebookPixel.trackCustom(FacebookPixel.URL_COMPLETE, { value: url });
          setStep(step + 1);
        }
      } else if (url && email) {
        await axios.post(API_DEMO_REGISTER, {
          url: secureProtocol ? `https://${url}` : `http://${url}`,
          email,
          ...utm,
        });

        setViolations([]);
        setEmailFeedback('');
        setEmailCheck(!!email);
      }
    } catch (error) {
      if (!error?.response?.data?.violations) {
        console.warn('Unknown error on step 1', error);
        setFeedback(t('components.landing.index.reasons.try_again'));
      } else {
        setViolations(error?.response?.data?.violations);
      }
    }

    setIsDisable(false);
    setLoading(false);
  };

  if (typeof window === 'object' && isAllowModal) {
    document.onmouseleave = () => setIsOpen(true);
  }
  const closeModalUntilRefresh = () => {
    setIsOpen(false);
    setIsAllowModal(false);
    Cookies.set(LANDING_PROMO_MODAL_COOKIE, true, { expires: 7 });
    document.onmouseleave = () => setIsOpen(false);
  };

  const customStyles = {
    overlay: {
      background: 'rgb(50, 50, 50,0.80)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      background: '#16171b',
      border: 'none',
      padding: '0px',
    },
  };
  const videoSwitcher = () => {
    const accept = document.getElementById('#accept');
    const sad = document.getElementById('#sad');
    const neutral = document.getElementById('#neutral');
    const declineButton = document.getElementById('#declineBut');
    const acceptButton = document.getElementById('#acceptBut');

    declineButton.onmouseenter = () => {
      sad.classList.toggle('d-none');
      neutral.classList.toggle('d-none');
    };
    declineButton.onmouseleave = () => {
      sad.classList.toggle('d-none');
      neutral.classList.toggle('d-none');
    };
    acceptButton.onmouseenter = () => {
      neutral.classList.toggle('d-none');
      accept.classList.toggle('d-none');
    };
    acceptButton.onmouseleave = () => {
      neutral.classList.toggle('d-none');
      accept.classList.toggle('d-none');
    };
    acceptButton.onclick = () => {
      const form = document.querySelector('#form');
      form.scrollIntoView();
    };
  };
  return (
    <Container fluid={true}>
      {!token ? (
        <Container>
          <Modal isOpen={modalIsOpen} style={customStyles} onAfterOpen={videoSwitcher}>
            <ReactPlayer
              playing
              muted
              loop={true}
              url="video/exit-popup_neutral.mp4"
              width={733}
              height={412}
              className="col-lg-8 px-0"
              id="#neutral"
            />
            <ReactPlayer
              playing
              muted
              loop={true}
              url="video/exit-popup_accept.mp4"
              width={733}
              height={412}
              className="d-none col-lg-8 px-0"
              id="#accept"
            />
            <ReactPlayer
              playing
              muted
              loop={true}
              url="video/exit-popup_sad.mp4"
              width={733}
              height={412}
              className="d-none col-lg-8 px-0"
              id="#sad"
            />
            <Container className="d-flex  my-4">
              <Container>
                <BackButton onClick={closeModalUntilRefresh} id="#declineBut">
                  {t('components.landing.index.declineButton')}
                </BackButton>
              </Container>
              <Container>
                <BuyButton onClick={closeModalUntilRefresh} id="#acceptBut">
                  {t('components.landing.index.acceptButton')}
                </BuyButton>
              </Container>
            </Container>
          </Modal>
        </Container>
      ) : (
        ''
      )}
      <Container>
        <CookieConsent
          cookieName={CONSENT_COOKIE}
          expires={90}
          onAccept={() => setConsentCookies(true)}
          location={'top'}
          buttonText="I accept the Cookie Policy"
        >
          This website uses cookies to enhance the user experience based on our{' '}
          <a href="/privacy-policy" style={{ 'text-decoration': 'underline' }} target="_blank">
            Privacy Policy
          </a>
          .
        </CookieConsent>

        <HomeSection id="home" className="row justify-content-center align-content-center mb-lg-0 my-5">
          <div className="col-10">
            <div className="row">
              <Title>
                <Trans i18nKey="components.landing.index.title">
                  Traffic for your website <br /> in just few minutes <label>FOR FREE</label>
                </Trans>
              </Title>
            </div>
            <div className="row">
              <Subtitle className="subtitle my-lg-4 my-2">{t('components.landing.index.subtitle')}</Subtitle>
            </div>
            <div className="row">
              <FormNote id="note" className={`${feedback ? 'd-none' : ''} mt-lg-2 mt-2 invisible`}>
                <EmojiIcon>⚠️</EmojiIcon>
                {t('components.landing.index.note')}
              </FormNote>
              <FormNote id="invalidUrlAlert" className={`${feedback ? '' : 'd-none'} mt-lg-2 mt-2`}>
                <EmojiIcon>⛔</EmojiIcon>
                {feedback}
              </FormNote>
            </div>
          </div>

          <Form onSubmit={onSubmit} className="row col-10 row-cols-auto my-lg-5 my-4 justify-content-center">
            <div className="col-lg-9 col-12 px-lg-0 px-0">
              <ProtocolSelect onClick={() => setSecureProtocol(!secureProtocol)}>
                {secureProtocol ? 'https://' : 'http://'}
              </ProtocolSelect>
              <FormInput
                id="url"
                className="accent-pink form-control data-hj-allow"
                name="url"
                placeholder={t('components.landing.index.placeholder')}
                value={url}
                onChange={(e) => {
                  const protocol = e.target.value.match(/^https?:\/\//);

                  if (protocol) {
                    setSecureProtocol(protocol[0] === 'https://');
                    e.target.value = e.target.value.replace(protocol[0], '');
                  }

                  setUrl(e.target.value);
                  if (feedback) {
                    setFeedback('');
                  }
                }}
                invalid={!!feedback}
              />
            </div>
            <div className="col-lg-3 col-12 row-cols-1 px-lg-1 px-0 mt-lg-0 mt-3 justify-content">
              <FormButton type="submit" className={`${loading ? 'loading' : ''} btn btn-primary`} disabled={isDisable}>
                {!loading ? 'CONTINUE' : ''}
              </FormButton>
            </div>

            <Discount className="mt-4">
              <icon className="animate__animated animate__wobble animate__repeat-3 d-inline-block">🎁</icon>{' '}
              {t('components.landing.index.discount')} <strong>-{welcomeBonusPercents}%</strong>
            </Discount>
          </Form>

          <div className="row col-lg-8 col-10 mt-lg-4 py-lg-5 py-3 services">
            <img src="img/services.svg" alt="trafficbooster.pro - website traffic services and platform support" />
          </div>
        </HomeSection>

        <section id="how-it-works" className="row justify-content-center align-content-center">
          <SectionContent className="col-10 pb-lg-5">
            <div className="row justify-content-center mb-2">
              <SectionTitle className="my-lg-4 col-lg-6 mb-2 animate__animated animate__fadeInUp">
                {t('components.landing.index.sectionHowItWorks')}
              </SectionTitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6 px-0 animate__animated animate__fadeInUp">
                <Trans i18nKey="components.landing.index.sectionBeingAn" defaults="" components={{ bold: <b /> }} />
              </SectionSubtitle>
            </div>

            <div className="row justify-content-center my-lg-5 my-2">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=nKwQMi1rpUw"
                controls={true}
                light="img/tb-poster.jpeg"
                playing={true}
                width={733}
                height={458}
                config={{
                  youtube: {
                    playerVars: {
                      fs: 1,
                      playsinline: 0,
                      enablejsapi: 1,
                      modestbranding: 1,
                    },
                  },
                }}
                className="col-lg-8 px-0"
              />
            </div>

            <div className="row justify-content-center my-lg-5 my-4">
              <TryButton className="btn btn-secondary col-lg-3 col-12 accent-green" data-scroll="#form">
                {t('components.landing.index.tryButton')}
              </TryButton>
            </div>
          </SectionContent>
        </section>

        <section id="features" className="row justify-content-center align-content-center my-lg-4 my-5">
          <SectionContent className="col-10 pb-lg-5 pb-4">
            <div className="row justify-content-center my-2">
              <SectionTitle className="my-lg-4 col-lg-6 mb-2" data-animate="fadeInUp">
                {t('components.landing.index.WhatYouGet')}
              </SectionTitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
                <Trans i18nKey="components.landing.index.benefits" defaults="" components={{ bold: <b /> }} />
              </SectionSubtitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
                <Trans i18nKey="components.landing.index.itsSafeForAdsense" defaults="" components={{ bold: <b /> }} />
              </SectionSubtitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
                <Trans i18nKey="components.landing.index.WeGuarantee" defaults="" components={{ bold: <b /> }} />
              </SectionSubtitle>
            </div>

            <div className="row justify-content-center g-5 my-lg-4 my-2">
              <Feature className="col-lg-5 position-relative" data-animate="fadeInUp">
                <FeatureIcon className="fa fa-user-shield fa-align-center position-relative translate-middle-x start-50" />
                <h4 className="my-3">{t('components.landing.index.feature.UpTo100k')}</h4>
                <p className="px-3">{t('components.landing.index.feature.HitAmounts')}</p>
              </Feature>

              <Feature className="col-lg-5 position-relative" data-animate="fadeInUp">
                <FeatureIcon className="fa fa-share fa-align-center position-relative translate-middle-x start-50" />
                <h4 className="my-3">{t('components.landing.index.feature.ReturnRate')}</h4>
                <p className="px-3">{t('components.landing.index.feature.ChooseVisitor')}</p>
              </Feature>

              <Feature className="col-lg-5 position-relative" data-animate="fadeInUp">
                <FeatureIcon className="fa fa-running fa-align-center position-relative translate-middle-x start-50" />
                <h4 className="my-3">{t('components.landing.index.feature.BounceRate')}</h4>
                <p className="px-3">{t('components.landing.index.feature.DecideAndSet')}</p>
              </Feature>

              <Feature className="col-lg-5 position-relative" data-animate="fadeInUp">
                <FeatureIcon className="fa fa-stopwatch fa-align-center position-relative translate-middle-x start-50" />
                <h4 className="my-3">{t('components.landing.index.feature.SessionTime')}</h4>
                <p className="px-3">{t('components.landing.index.feature.WithFreeAccount')}</p>
              </Feature>

              <Feature className="col-lg-5 position-relative" data-animate="fadeInUp">
                <FeatureIcon className="fa fa-mobile-alt fa-align-center position-relative translate-middle-x start-50" />
                <h4 className="my-3">{t('components.landing.index.feature.DesktopMobile')}</h4>
                <p className="px-3">{t('components.landing.index.feature.WhoDetermine')}</p>
              </Feature>

              <Feature className="col-lg-5 position-relative" data-animate="fadeInUp">
                <FeatureIcon className="fa fa-compass fa-align-center position-relative translate-middle-x start-50" />
                <h4 className="my-3">{t('components.landing.index.feature.DirectOrganic')}</h4>
                <p className="px-3">{t('components.landing.index.feature.AbleToSelect')}</p>
              </Feature>
            </div>
          </SectionContent>
        </section>

        <section id="mission" className="row justify-content-center align-content-center">
          <SectionContent className="col-10 pb-5">
            <div className="row justify-content-center my-2">
              <SectionTitle className="my-lg-4 col-lg-6 mb-2" data-animate="fadeInUp">
                {t('components.landing.index.mission.OurMission')}
              </SectionTitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
                {t('components.landing.index.mission.description')}
              </SectionSubtitle>
            </div>

            <div className="row justify-content-evenly g-5 my-lg-4">
              <MissionPoint className="col-lg-7 p-4 mt-lg-5" data-animate="fadeInUp">
                <h3 className="my-lg-4">{t('components.landing.index.mission.ProService')}</h3>
                <p>{t('components.landing.index.mission.proForEveryone')}</p>
              </MissionPoint>
              <div className="col-lg-3 col-6" data-animate="fadeInUp">
                <img src="img/pro.svg" alt="trafficbooster.pro - professional tool for website traffic boost" />
              </div>
              <div className="col-lg-3 col-6" data-animate="fadeInUp">
                <img src="img/less.svg" alt="trafficbooster.pro - professional tool for website traffic boost" />
              </div>
              <MissionPoint className="col-lg-7 p-4 my-lg-5" data-animate="fadeInUp">
                <h3 className="my-lg-4 text-end">{t('components.landing.index.mission.AfforableCosts')}</h3>
                <p className="text-end">{t('components.landing.index.mission.costs')}</p>
              </MissionPoint>
            </div>

            <div className="row justify-content-lg-end m-lg-5 px-lg-2">
              <ButtonWrapper className="col-lg-3 justify-content-center" data-animate="fadeInUp">
                <TryButtonEmpty className="btn btn-secondary accent-pink" data-scroll="#form">
                  {t('components.landing.index.tryButton')}
                </TryButtonEmpty>
              </ButtonWrapper>
            </div>
          </SectionContent>
        </section>
      </Container>

      <section id="pricing" className="row justify-content-center align-content-center px-xl-0 mx-xl-0 mx-xxl-4">
        <SectionContent className="pb-5">
          <div className="row justify-content-center my-2">
            <SectionTitle className="my-lg-4 col-lg-6 mb-2" data-animate="fadeInUp">
              {t('components.landing.index.pricing.Pricing')}
            </SectionTitle>
          </div>

          <div className="row justify-content-center my-2">
            <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
              {t('components.landing.index.pricing.description')}
            </SectionSubtitle>
          </div>

          <Packages forMainPage={true} />
        </SectionContent>
      </section>

      <Container>
        <section id="faq" className="row justify-content-center align-content-center">
          <SectionContent className="col-10 py-5">
            <div className="row justify-content-center my-2">
              <SectionTitle className="my-lg-4 col-lg-6 mb-2" data-animate="fadeInUp">
                {t('components.landing.index.FrequentlyAsked')}
              </SectionTitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
                {t('components.landing.index.GatheredTogether')}
              </SectionSubtitle>
            </div>

            <div className="row justify-content-center g-5 my-4">
              <div className="col-lg-6 mt-lg-4 mt-0 px-lg-2 px-0">
                <div className="accordion" id="accordion-left">
                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="q-1">
                      <AccordionButton
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#a-1"
                        aria-expanded="true"
                        aria-controls="a-1"
                      >
                        {t('components.landing.index.accordion.IsReal')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="a-1"
                      className="accordion-collapse collapse show"
                      aria-labelledby="q-1"
                      data-bs-parent="#accordion-left"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.OurTraffic')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="q-2">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#a-2"
                        aria-expanded="false"
                        aria-controls="a-2"
                      >
                        {t('components.landing.index.accordion.HowCanHelp')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="a-2"
                      className="accordion-collapse collapse"
                      aria-labelledby="q-2"
                      data-bs-parent="#accordion-left"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.MostUsers')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="q-3">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#a-3"
                        aria-expanded="false"
                        aria-controls="a-3"
                      >
                        {t('components.landing.index.accordion.WhyMyWebsite')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="a-3"
                      className="accordion-collapse collapse"
                      aria-labelledby="q-3"
                      data-bs-parent="#accordion-left"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.IfYouOnlyHere')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="q-4">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#a-4"
                        aria-expanded="false"
                        aria-controls="a-4"
                      >
                        {t('components.landing.index.accordion.IsItSafe')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="a-4"
                      className="accordion-collapse collapse"
                      aria-labelledby="q-4"
                      data-bs-parent="#accordion-left"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.AbsolutelySafe')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="q-5">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#a-5"
                        aria-expanded="false"
                        aria-controls="a-5"
                      >
                        {t('components.landing.index.accordion.WhatRatePresent')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="a-5"
                      className="accordion-collapse collapse"
                      aria-labelledby="q-5"
                      data-bs-parent="#accordion-left"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.RatePercent')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="q-6">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#a-6"
                        aria-expanded="false"
                        aria-controls="a-6"
                      >
                        {t('components.landing.index.accordion.WhatRateHas')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="a-6"
                      className="accordion-collapse collapse"
                      aria-labelledby="q-6"
                      data-bs-parent="#accordion-left"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.YouCanTell')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>
                </div>
              </div>

              <div className="col-lg-6 mt-lg-4 mt-0 px-lg-2 px-0">
                <div className="accordion" id="accordion-right">
                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="qr-1">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ar-1"
                        aria-expanded="true"
                        aria-controls="ar-1"
                      >
                        {t('components.landing.index.accordion.WhichBounceRate')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="ar-1"
                      className="accordion-collapse collapse"
                      aria-labelledby="qr-1"
                      data-bs-parent="#accordion-right"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.GiveYouAnswer')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="qr-2">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ar-2"
                        aria-expanded="false"
                        aria-controls="ar-2"
                      >
                        {t('components.landing.index.accordion.WillTrafficAsist')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="ar-2"
                      className="accordion-collapse collapse"
                      aria-labelledby="qr-2"
                      data-bs-parent="#accordion-right"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.GoogleWorks')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="qr-3">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ar-3"
                        aria-expanded="false"
                        aria-controls="ar-3"
                      >
                        {t('components.landing.index.accordion.WhatIsAlexa')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="ar-3"
                      className="accordion-collapse collapse"
                      aria-labelledby="qr-3"
                      data-bs-parent="#accordion-right"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.WhenHaveKind')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="qr-4">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ar-4"
                        aria-expanded="false"
                        aria-controls="ar-4"
                      >
                        {t('components.landing.index.accordion.CanTrafficImprove')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="ar-4"
                      className="accordion-collapse collapse"
                      aria-labelledby="qr-4"
                      data-bs-parent="#accordion-right"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.ActiveCertified')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" data-animate="fadeInUp">
                    <AccordionHeader id="qr-6">
                      <AccordionButton
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ar-6"
                        aria-expanded="false"
                        aria-controls="ar-6"
                      >
                        {t('components.landing.index.accordion.AcceptAdult')}
                      </AccordionButton>
                    </AccordionHeader>
                    <div
                      id="ar-6"
                      className="accordion-collapse collapse"
                      aria-labelledby="qr-6"
                      data-bs-parent="#accordion-right"
                    >
                      <AccordionBody className="accordion-body col-10">
                        {t('components.landing.index.accordion.WorkWithAdult')}
                      </AccordionBody>
                    </div>
                  </AccordionItem>
                </div>
              </div>

              <div className="row justify-content-center my-lg-5 mt-5">
                <TryButton className="btn btn-secondary col-lg-3 col-12 accent-green" data-scroll="#form">
                  {t('components.landing.index.tryButton')}
                </TryButton>
              </div>
            </div>
          </SectionContent>
        </section>

        <section id="feedbacks" className="row justify-content-center align-content-center">
          <SectionContent className="col-10 py-5">
            <div className="row justify-content-center my-2">
              <SectionTitle className="my-lg-4 col-lg-6 mb-2" data-animate="fadeInUp">
                <Trans i18nKey="components.landing.index.WhatOtherPro">
                  What other <b>PRO</b> say about us
                </Trans>
              </SectionTitle>
            </div>

            <div className="row justify-content-center my-2">
              <SectionSubtitle className="row-cols-6 col-lg-6" data-animate="fadeInUp">
                {t('components.landing.index.CollectRelevant')}
              </SectionSubtitle>
            </div>

            <div className="row justify-content-center my-4">
              <Feedback className="col-lg-5 col-12 mx-lg-2 mx-4 position-relative" data-animate="fadeInUp">
                <div className="row pt-5 pb-2 justify-content-center">
                  <div className="col-lg-2 col-4 mb-lg-2 mb-4" data-animate="flipInY">
                    <Thumbnail
                      className="rounded-circle border-0"
                      src="img/feedback-pk.png"
                      alt="trafficbooster.pro - user feedbacks"
                    />
                  </div>
                  <div className="col-lg-10">
                    <FeedbackHeader className="row px-2 justify-content-lg-between">
                      <h5 className="col-lg-6">{t('components.landing.index.feedBack.user.name.Pk')}</h5>
                      <span className="col-lg-6">{`${t(
                        'components.landing.index.feedBack.user.profession.webmaster'
                      )}, 23 ${t('components.landing.index.feedBack.user.years')}`}</span>
                    </FeedbackHeader>
                    <FeedbackText className="d-block px-2 mt-4">
                      {t('components.landing.index.feedBack.user.feedback.webmaster')}
                    </FeedbackText>
                  </div>
                </div>
              </Feedback>

              <Feedback className="col-lg-5 col-12 mx-lg-2 mx-4 position-relative" data-animate="fadeInUp">
                <div className="row pt-5 pb-2 justify-content-center">
                  <div className="col-lg-2 col-4 mb-lg-2 mb-4" data-animate="flipInY">
                    <Thumbnail
                      className="rounded-circle border-0"
                      src="img/feedback-gb.jpeg"
                      alt="trafficbooster.pro - user feedbacks"
                    />
                  </div>
                  <div className="col-lg-10">
                    <FeedbackHeader className="row px-2 justify-content-lg-between">
                      <h5 className="col-lg-6">{t('components.landing.index.feedBack.user.name.Gb')}</h5>
                      <span className="col-lg-6">{`${t(
                        'components.landing.index.feedBack.user.profession.productOwner'
                      )}, 36 ${t('components.landing.index.feedBack.user.years')}`}</span>
                    </FeedbackHeader>
                    <FeedbackText className="d-block px-2 mt-4">
                      {t('components.landing.index.feedBack.user.feedback.productOwner')}
                    </FeedbackText>
                  </div>
                </div>
              </Feedback>

              <Feedback className="col-lg-5 col-12 mx-lg-2 mx-4 position-relative" data-animate="fadeInUp">
                <div className="row pt-5 pb-2 justify-content-center">
                  <div className="col-lg-2 col-4 mb-lg-2 mb-4" data-animate="flipInY">
                    <Thumbnail
                      className="rounded-circle border-0"
                      src="img/feedback-in.jpeg"
                      alt="trafficbooster.pro - user feedbacks"
                    />
                  </div>
                  <div className="col-lg-10">
                    <FeedbackHeader className="row px-2 justify-content-lg-between">
                      <h5 className="col-lg-6">{t('components.landing.index.feedBack.user.name.In')}</h5>
                      <span className="col-lg-6">{`${t(
                        'components.landing.index.feedBack.user.profession.seo'
                      )}, 34 ${t('components.landing.index.feedBack.user.years')}`}</span>
                    </FeedbackHeader>
                    <FeedbackText className="d-block px-2 mt-4">
                      {t('components.landing.index.feedBack.user.feedback.seo')}
                    </FeedbackText>
                  </div>
                </div>
              </Feedback>

              <Feedback className="col-lg-5 col-12 mx-lg-2 mx-4 position-relative" data-animate="fadeInUp">
                <div className="row pt-5 pb-2 justify-content-center">
                  <div className="col-lg-2 col-4 mb-lg-2 mb-4" data-animate="flipInY">
                    <Thumbnail
                      className="rounded-circle border-0"
                      src="img/feedback-ca.jpeg"
                      alt="trafficbooster.pro - user feedbacks"
                    />
                  </div>
                  <div className="col-lg-10">
                    <FeedbackHeader className="row px-2 justify-content-lg-between">
                      <h5 className="col-lg-6">{t('components.landing.index.feedBack.user.name.Ca')}</h5>
                      <span className="col-lg-6">{`${t(
                        'components.landing.index.feedBack.user.profession.ceo'
                      )}, 38 ${t('components.landing.index.feedBack.user.years')}`}</span>
                    </FeedbackHeader>
                    <FeedbackText className="d-block px-2 mt-4">
                      {t('components.landing.index.feedBack.user.feedback.ceo')}
                    </FeedbackText>
                  </div>
                </div>
              </Feedback>

              <Feedback className="col-lg-5 col-12 mx-lg-2 mx-4 position-relative" data-animate="fadeInUp">
                <div className="row pt-5 pb-2 justify-content-center">
                  <div className="col-lg-2 col-4 mb-lg-2 mb-4" data-animate="flipInY">
                    <Thumbnail
                      className="rounded-circle border-0"
                      src="img/feedback-id.jpeg"
                      alt="trafficbooster.pro - user feedbacks"
                    />
                  </div>
                  <div className="col-lg-10">
                    <FeedbackHeader className="row px-2 justify-content-lg-between">
                      <h5 className="col-lg-6">{t('components.landing.index.feedBack.user.name.Id')}</h5>
                      <span className="col-lg-6">{`${t(
                        'components.landing.index.feedBack.user.profession.smm'
                      )}, 26 ${t('components.landing.index.feedBack.user.years')}`}</span>
                    </FeedbackHeader>
                    <FeedbackText className="d-block px-2 mt-4">
                      {t('components.landing.index.feedBack.user.feedback.smm')}
                    </FeedbackText>
                  </div>
                </div>
              </Feedback>

              <Feedback className="col-lg-5 col-12 mx-lg-2 mx-4 position-relative" data-animate="fadeInUp">
                <div className="row pt-5 pb-2 justify-content-center">
                  <div className="col-lg-2 col-4 mb-lg-2 mb-4" data-animate="flipInY">
                    <Thumbnail
                      className="rounded-circle border-0"
                      src="img/feedback-us.jpeg"
                      alt="trafficbooster.pro - user feedbacks"
                    />
                  </div>
                  <div className="col-lg-10">
                    <FeedbackHeader className="row px-2 justify-content-lg-between">
                      <h5 className="col-lg-6">{t('components.landing.index.feedBack.user.name.Us')}</h5>
                      <span className="col-lg-6">{`${t(
                        'components.landing.index.feedBack.user.profession.eCommerce'
                      )}, 23 ${t('components.landing.index.feedBack.user.years')}`}</span>
                    </FeedbackHeader>
                    <FeedbackText className="d-block px-2 mt-4">
                      {t('components.landing.index.feedBack.user.feedback.eCommerce')}
                    </FeedbackText>
                  </div>
                </div>
              </Feedback>

              <div className="row justify-content-center m-lg-5 mt-5 px-lg-2 px-0">
                <ButtonWrapper className="col-lg-3 justify-content-center" data-scroll="#form" data-animate="fadeInUp">
                  <TryButtonEmpty className="btn btn-secondary accent-pink" data-scroll="#form">
                    {t('components.landing.index.feedBack.user.tryButtonEmpty')}
                  </TryButtonEmpty>
                </ButtonWrapper>
              </div>
            </div>
          </SectionContent>
        </section>
      </Container>

      <FormSection id="form" className="row justify-content-center align-content-center">
        <FormContent className="col-10">
          <div className="row">
            <FormTitle className={step === 0 || step === 1 ? '' : 'd-none'}>
              {t('components.landing.index.form.getFreeTraffic')}
            </FormTitle>
          </div>

          <div className={(step === 0 || step === 1 ? '' : 'd-none') + ' row'} data-quiz-class="d-none">
            <FormSubtitle className="mt-lg-5 mt-4" data-animate="fadeInUp">
              {t('components.landing.index.form.step1/3')}
            </FormSubtitle>
          </div>

          <div className={(step === 0 || step === 1 ? '' : 'd-none') + ' row'} data-quiz-class="d-none">
            <FormNote id="note-form" className="mt-lg-2 mt-4 invisible">
              <EmojiIcon>⚠️</EmojiIcon>
              {t('components.landing.index.form.noteYouShould')}
            </FormNote>
          </div>

          <div className={(step === 0 || step === 1 ? '' : 'd-none') + (feedback ? '' : ' invisible') + ' row'}>
            <FormNote className="mt-lg-2 mt-4">
              <EmojiIcon>⛔</EmojiIcon>
              {feedback}
            </FormNote>
          </div>

          <div className={(step === 2 ? '' : 'd-none') + ' row justify-content-center'} data-quiz-class="d-none">
            <img className="col-lg-2 col-6" src="img/scan-loading.svg" alt="trafficbooster.pro - scanning website" />
            <FormSubtitle className="mt-lg-5 mt-4 text-center">
              {t('components.landing.index.form.scanningWebsite')}
            </FormSubtitle>
          </div>

          <div className={(step === 3 ? '' : 'd-none') + ' row justify-content-center'} data-quiz-class="d-none">
            <img className="col-lg-2 col-6" src="img/gear-loading.svg" alt="trafficbooster.pro - building project" />
            <FormSubtitle className="mt-lg-5 mt-4 text-center">
              {t('components.landing.index.form.buildingProject')}
            </FormSubtitle>
          </div>

          <div className={(step === 4 ? '' : 'd-none') + ' row justify-content-center'} data-quiz-class="d-none">
            <img
              className="col-lg-2 col-6"
              src="img/search-loading.svg"
              alt="trafficbooster.pro - searching for traffic"
            />
            <FormSubtitle className="mt-lg-5 mt-4 text-center">
              {t('components.landing.index.form.searchingVisitors')}
            </FormSubtitle>
          </div>

          <div className={(step === 5 ? '' : 'd-none') + ' row'} data-quiz-class="d-none">
            <FormSubtitle className="mt-lg-5 mt-4">{t('components.landing.index.form.step2/3')}</FormSubtitle>
          </div>

          <div className={(step === 6 ? '' : 'd-none') + ' row'} data-quiz-class="d-none">
            <FormSubtitle className="mt-lg-5 mt-4">{t('components.landing.index.form.step3/3')}</FormSubtitle>
          </div>

          <div className={(step === 6 ? '' : 'd-none') + ' row'} data-quiz-class="d-none">
            <FormNote className="mt-lg-2 mt-4">
              <EmojiIcon>✉️</EmojiIcon>
              {t('components.landing.index.form.weSentAnEmail')}
            </FormNote>
            <FormNote className="mt-lg-2 mt-4">
              <EmojiIcon>📥</EmojiIcon>
              {t('components.landing.index.form.mailSentFrom')}
            </FormNote>
          </div>

          <div className={(step === 5 ? '' : 'd-none') + ' row'} data-quiz-class="d-none">
            <FormNote className="mt-lg-2 mt-4">
              <EmojiIcon>✅</EmojiIcon>
              <Trans i18nKey="components.landing.index.form.weFoundPotential">
                We found {{ random }} potential visitor(s) for your website on next 48 hours.
              </Trans>
            </FormNote>
            <FormNote className="mt-lg-2 mt-4">
              <EmojiIcon>🤖</EmojiIcon>
              {t('components.landing.index.form.weAboutRun')}
            </FormNote>
          </div>

          <div
            id="invalidEmailAlert"
            className={(step === 5 ? '' : 'd-none') + (emailFeedback ? '' : ' invisible') + ' row'}
          >
            <FormNote className="mt-lg-2 mt-4">
              <EmojiIcon>⛔</EmojiIcon>
              {emailFeedback}
            </FormNote>
          </div>

          <Form onSubmit={onSubmit} className="row row-cols-auto my-3 justify-content-center">
            <div
              className={(step === 0 || step === 1 ? '' : 'd-none') + ' col-lg-9 col-12 px-0'}
              data-quiz-class="d-none"
              data-animate="fadeInUp"
            >
              <ProtocolSelect onClick={() => setSecureProtocol(!secureProtocol)}>
                {secureProtocol ? 'https://' : 'http://'}
              </ProtocolSelect>
              <FormInput
                id="url-form"
                className="accent-pink form-control data-hj-allow"
                name="url"
                placeholder={t('components.landing.index.form.placeholderUrl')}
                value={url}
                onChange={(e) => {
                  const protocol = e.target.value.match(/^https?:\/\//);

                  if (protocol) {
                    setSecureProtocol(protocol[0] === 'https://');
                    e.target.value = e.target.value.replace(protocol[0], '');
                  }

                  setUrl(e.target.value);
                  if (feedback) {
                    setFeedback('');
                  }
                }}
                invalid={!!feedback}
              />
            </div>
            <div
              className={
                (step === 0 || step === 1 ? '' : 'd-none') +
                ' col-lg-3 col-12 row-cols-1 px-lg-1 px-0 mt-lg-0 mt-3 justify-content'
              }
              data-quiz-class="d-none"
              data-animate="fadeInUp"
            >
              <FormButton type="submit" className={`${loading ? 'loading' : ''} btn btn-primary`} disabled={isDisable}>
                {!loading ? 'CONTINUE' : ''}
              </FormButton>
            </div>
            <div className={(step === 5 ? '' : 'd-none') + ' col-lg-9 col-12 px-0'}>
              <FormInputFull
                id="email-form"
                className="accent-pink form-control data-hj-allow"
                type="email"
                name="email"
                placeholder={t('components.landing.index.form.placeholderEmail')}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (feedback) {
                    setFeedback('');
                  }
                  if (emailFeedback) {
                    setEmailCheck(false);
                    setEmailFeedback('');
                  }
                }}
                invalid={!!feedback}
              />
            </div>
            <div
              className={
                (step === 5 ? '' : 'd-none') + ' col-lg-3 col-12 row-cols-1 px-lg-1 px-0 mt-lg-0 mt-3 justify-content'
              }
            >
              <FormButton type="submit" className={`${loading ? 'loading' : ''} btn btn-primary`} disabled={isDisable}>
                {!loading ? 'VERIFY' : ''}
              </FormButton>
            </div>
            <div className={(step === 5 ? '' : 'd-none') + ' col-12 px-0 mt-3'}>
              {t('components.landing.index.agreement.iAgree')}&nbsp;
              <a href={EULA} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                {t('components.landing.index.agreement.eula')}
              </a>
              &nbsp;
              {t('components.landing.index.agreement.and')}&nbsp;
              <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                {t('components.landing.index.agreement.privacyPolicy')}
              </a>
            </div>
            <Discount className="mt-4">
              <icon className="animate__animated animate__wobble animate__repeat-3 d-inline-block">🎁</icon>{' '}
              {t('components.landing.index.discount')} <strong className="contrast">-{welcomeBonusPercents}%</strong>
            </Discount>
          </Form>
        </FormContent>
      </FormSection>
    </Container>
  );
};

export default Landing;
